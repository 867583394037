import React from 'react'
import Chicken from './images/chickenNew2.webp'
import Sandwich from './images/sandwichNew2.webp'
import HotDog from './images/hot-dogNew2.webp'
import Pizza from './images/pizzaNew2.webp'
import Burger from './images/burgerNew22.webp'
import Fries from './images/friesNew2.webp'


function IconBar() {
    return (
        <div className="iconBar container-fluid text-center">
            <div className="row row-cols-3 row-cols-md-6 row-cols-lg-6">
                <div className="col">
                    <img src={Chicken} height="100px" width="100px" alt="Chicken" className="shake-slow" />
                    <p aria-label="chicken" name="chicken">Chicken</p>
                </div>
                <div className="col">
                    <img src={Sandwich} height="100px" width="100px" alt="Sandwich" className="shake-slow" />
                    <p aria-label="sandwhiches" name="sandwhiches">Sandwiches</p>
                </div><div className="col">
                    <img src={HotDog} height="100px" width="100px" alt="Hot Dog" className="shake-slow" />
                    <p aria-label="hot dogs" name="hot dogs">Hot Dogs</p>
                </div><div className="col">
                    <img src={Pizza} height="100px" width="100px" alt="Pizza" className="shake-slow" />
                    <p aria-label="pizzas" name="pizzas">Pizzas</p>
                </div><div className="col">
                    <img src={Burger} height="100px" width="100px" alt="Burger" className="shake-slow" />
                    <p aria-label="burgers" name="burgers">Burgers</p>
                </div><div className="col">
                    <img src={Fries} height="100px" width="100px" alt="Fries" className="shake-slow" />
                    <p aria-label="fries" name="fries">Fries</p>
                </div>
            </div>
        </div>
    )
}

export default IconBar
