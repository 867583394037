import React, { useState, useEffect } from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api';
import HoagiesMarker from './images/HOAGIES_MARKER_FTG.webp'
import HoagiesMarkerCYO from './images/HOAGIES_MARKER_CYO.webp'

function Marker0(props) {

    const [show, setShow] = useState(false)
    const [cyo, setCyo] = useState(false)

    useEffect(() => {
        console.log(props.marker)
        if (props.marker.utilities.hoagiesCYO) {
            setCyo(true)
        } else {
            setCyo(false)
        }
    }, [props])

    const showInfo = () => {
        setShow(!show)
    }

    const divStyle = {
        border: "2px solid black",
        'borderRadius': "10px",
        padding: 5,
        fontSize: "0.5rem",
        'textAlign': "center",
        background: `rgb(255, 217, 0)`,
        // height: '18vh',
    }
    return (
        <div>
            <Marker icon={cyo? HoagiesMarkerCYO:HoagiesMarker} position={{ lat: props.marker.Address.latitude, lng: props.marker.Address.longitude }} key={props.marker._id} onClick={showInfo} />
            {show ?
                <InfoWindow onCloseClick={showInfo} position={{ lat: props.marker.Address.latitude, lng: props.marker.Address.longitude }} >
                    <div style={divStyle}>
                        <p><u>{cyo? "Hoagies - Create Your Own":"Hoagies - Food To Go"}</u></p>
                        <p>{props.marker.Address.addressLine1}</p>
                        <p>{props.marker.Address.addressLine2}, {props.marker.Address.addressLine3}</p>
                        <p><a href={`https://maps.google.com/?q=${props.marker.Address.addressLine1}+${props.marker.Address.postcode}`} target="_blank" rel="noreferrer">{props.marker.Address.postcode}</a></p>
                    </div>
                </InfoWindow>
                : <></>
            }
        </div>
    )
}

export default Marker0
