import React from 'react'
// import Bannerweb from './images/Website_Banner_2.png'

function Banner() {
    return (
        <div className="banner">
            {/* <img src={Bannerweb} width="100%" alt="Hoagies Banner"/> */}
        </div>
    )
}

export default Banner
