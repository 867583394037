import React from "react";
import HoagiesLogo from "./images/Artboard 2.webp";
import { FiInstagram } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { SiTiktok } from "react-icons/si";

function Footer() {
  return (
    <div>
      <footer className="footer page-footer font-small mdb-color darken-3 pt-4">
        <div className="container">
          <div className="row row-cols row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
            <div className="col text-center">
              <br />
              <p>
                <i className="glyphicon glyphicon-envelope icons" />{" "}
                <a className="link" href="mailto:info@hoagies.co.uk">
                  info@hoagies.co.uk
                </a>
              </p>
              <p className="mb-5">Follow Us: @hoagiesofficial</p>
              <a
                className="media"
                href="https://www.facebook.com/hoagiesofficial/"
              >
                <FiFacebook className="mediaIcons"/>
              </a>
              <a
                className="media"
                href="https://www.instagram.com/hoagiesofficial/"
              >
                <FiInstagram className="mediaIcons"/>
              </a>
              <a
                className="media"
                href="https://www.tiktok.com/@hoagiesofficial"
              >
                <SiTiktok className="mediaIcons"/>
              </a>
            </div>
            <div className="col text-center">
              <img src={HoagiesLogo} alt="Hoagies Logo" />
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center py-3">
          <hr />© Hoagies | Developed and Maintained by
          <a href="https://nitrosolutions.co.uk/"> Nitro Solutions</a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
