import React from 'react'
import Navbar from '../Navbar'
import NewMenu from '../NewMenu'
import Footer from '../Footer'

function Menu() {
    return (
        <div>
            <Navbar/>
            <NewMenu/>
            <Footer/>
        </div>
    )
}

export default Menu
