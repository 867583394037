import React, { useState } from 'react'
import axios from 'axios'

function ContactForm() {

    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [message, setMessage] = useState('')
    const [msg, setMsg] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showError, setShowError] = useState(false)

    const submit = async e => {
        e.preventDefault();
        var data = {
            Name: (`${firstName} ${surname}`),
            Email: emailAddress,
            Message: message
        }
        await axios.post('https://nitrous.rt-itservices.co.uk/helpdesk/hoagies', data).then((res) => {
            if (res.data.Sent === true) {
                setMsg('Thank You For Your Enquiry. Someone From Our Team Will Be In Touch With You Within The Next 48 Hours')
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false);
                    setFirstName("");
                    setSurname("");
                    setEmailAddress("");
                    setMessage("");
                    setMsg('');
                }, 5000)
                }
        }).catch((err) => {
            console.log(err)
            setMsg('Form Could Not Be Submitted. Please Try Again Later.')
            setShowError(true)
            setTimeout(() => {
                setShowError(false);
                setFirstName("");
                setSurname("");
                setEmailAddress("");
                setMessage("");
                setMsg('');
            }, 5000)
        })
    }

    return (
        <div className="aboutUs">
            <div className="container">
                <h1 className="text-center heading" aria-label="contact us" name="contact us">Contact Us</h1>
                <p className="text-center heading2">Get in touch and we’ll get back to you as soon as we can. We look forward to hearing from you!</p>
                <div className="form">
                    <form>
                        <div className="form-group">
                            <label htmlFor="firstName" aria-label="first name" name="first name">First Name</label>
                            <input type="text" className="form-control formInput" id="firstName" value={firstName} onChange={e => { setFirstName(e.target.value) }} aria-label="first name." name="first name." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="surname" aria-label="surname" name="surname">Surname</label>
                            <input type="text" className="form-control formInput" id="surname" value={surname} onChange={e => { setSurname(e.target.value) }} aria-label="surname." name="surname." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email" aria-label="email address" name="email address">Email Address</label>
                            <input type="email" className="form-control formInput" id="email" value={emailAddress} onChange={e => { setEmailAddress(e.target.value) }} aria-label="email address." name="email address." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1" aria-label="enter your message" name="enter your message">Your Message</label>
                            <textarea className="form-control formInput" id="exampleFormControlTextarea1" rows="3" value={message} onChange={e => { setMessage(e.target.value) }} required  aria-label="enter your message." name="enter your message."/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-secondary px-5" onClick={submit} aria-label="submit button" name="submit button">Submit</button>
                        </div>
                    </form>

                    {showAlert ?
                        <div className="alert alert-info" role="alert">
                            <p id="msg1"  aria-label="alert information" name="alert information">{msg}</p>
                        </div>
                        :
                        <></>
                    }
                    {showError ?
                        <div className="error alert-info" role="alert">
                            <p id="msg1" aria-label="error information" name="error information">{msg}</p>
                        </div>
                        :
                        <></>}
                </div>
            </div>
            <br />
        </div>
    )
}

export default ContactForm
