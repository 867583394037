import React from 'react'
import HoagiesLogo from './images/Artboard 2.webp'
import BurgerMenu from './images/burgerNewSmall.webp'
function Navbar() {

    function home(){
        window.location.href="/"
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark">
            <img className="navbarLogo" src={HoagiesLogo} height='200px' width="350px" alt="Hoagies Logo" onClick={home}/>
            <button className="navBtn navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <i className="glyphicon" >
                <img className="burgerMenuIcon" src={BurgerMenu} alt="Burger Menu" />
                </i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                    <a className="t1 nav-item active" href="/">Home</a>
                    <a className="t1 nav-item" href="/menu">Menu</a>
                    <a className="t1 nav-item" href="/contact">Contact Us</a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
