import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/Pages/Home';
import Menu from './components/Pages/Menu';
import Contact from './components/Pages/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/menu" component={Menu} />
              <Route exact path="/contact" component={Contact} />
            </Switch>
          </BrowserRouter>
    </div>
  );
}

export default App;
