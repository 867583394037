import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import ContactForm from '../ContactForm'

function Contact() {
    return (
        <div>
            <Navbar/>
            <ContactForm/>
            <Footer/>
        </div>
    )
}

export default Contact
