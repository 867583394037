import React from "react";
import Map from "./Map";
function AboutUs() {
  return (
    <div className="aboutUs">
      <div className="blur container text-center">
        <h1 className="heading" aria-label="find us here" name="Find Us Here">Find Us Here</h1>
        <div className="card">
          <div className="map">
            <Map />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default AboutUs;
