import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import Marker0 from './Marker'

function Map() {

    const [markers1, setMarkers1] = useState([])
    useEffect(() => {
        axios.get('https://maps.rt-itservices.co.uk?site=Hoagies').then((res) => {
            setMarkers1(res.data.sites);
            //console.log(res.data.sites)
        })
    }, [])

    const containerStyle = {
        width: '100%',
        height: '340px'
    };

    const center = {
        lat: 55.877648982180375,
        lng: -4.347763994936593
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I"
    })

    return isLoaded ? (
        <GoogleMap id="marker-example" mapContainerStyle={containerStyle} zoom={10} center={center} options={{streetViewControl: false}}>
            {markers1.map(function (marker, i) {
                return (
                    <Marker0 key={i} marker={marker} />
                )
            })}
        </GoogleMap>
    ) : <></>
}

export default Map
