import React from 'react'
import Navbar from '../Navbar'
import Banner from '../Banner'
import IconBar from '../IconBar'
import AboutUs from '../AboutUs'
import Footer from '../Footer'

function Home() {
    return (
        <div>
            <Navbar />
            <Banner />
            <IconBar />
            <AboutUs />
            <Footer />
        </div>
    )
}

export default Home
